<template>
  <router-link :to="`/customers/${customerId}/branches/${branch.id}`" class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full px-2 rounded-lg">
    <div class="py-4">
      <div class="sm:flex flex-wrap">
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <HomeIcon class="w-6 h-6" />
              </span>
              {{branch.name}}
            </p>
          </template>
        </ItemText>
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <LocationMarkerIcon class="w-6 h-6" />
              </span>
              {{branch.address ? branch.address : 'Nav norādīts'}}
            </p>
          </template>
        </ItemText>
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <MapIcon class="w-6 h-6" />
              </span>
              {{branch.region ? branch.region.name : 'Nav norādīts'}}
            </p>
          </template>
        </ItemText>

        <div class="ml-auto">
          <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
            <button class="text-gray-400 flex items-center">
              Skatīt filiāli
              <ChevronRightIcon class="w-6 h-6"/>
            </button>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import {
  HomeIcon,
  LocationMarkerIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";
import {
  MapIcon,
} from "@heroicons/vue/outline";

export default {
  name: "BranchDetails",
  components: {
    ItemText,
    HomeIcon,
    LocationMarkerIcon,
    ChevronRightIcon,
    MapIcon
  },
  props: ['branch', 'customerId'],
}
</script>

<style>

</style>