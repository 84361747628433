<template>
  <!--  Show component according to Item type-->
  <component :is="getComponentByType" :item="item"/>
</template>

<script>
import CustomerType from "./ItemTypes/Customer/CustomerType";
import BranchType from "./ItemTypes/Branch/BranchType";
import ContactType from "./ItemTypes/Contact/ContactType";

export default {
  name: "ShowItem",
  props: ['item'],
  components: {
    CustomerType,
    BranchType,
    ContactType
  },
  data(){
    return {
      showContacts: false,
      showBranches: false,
      showCustomers: false,
    }
  },
  computed: {
    getComponentByType(){
      if(this.item.type === 'customers') {
        return 'customer-type'
      } else if (this.item.type === 'branches') {
        return 'branch-type'
      } else if (this.item.type === 'contacts') {
        return 'contact-type'
      }
      return ""
    }
  },
}
</script>

<style scoped>

</style>