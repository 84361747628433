<template>
  <item-text>
    <template v-slot:content>
      <div class="h-full flex items-center">
        <div class="w-9 h-9 bg-gray-400 rounded-full flex justify-center align-center text-white">
          <span class="text-2xl">Ko</span>
        </div>
      </div>
    </template>
  </item-text>

  <item-text title="Vārds Uzvārds" :text="item.name" />
  <item-text title="Telefona nr." :text="item.phone ? item.phone : 'Nav norādīts'" />
  <item-text title="E-pasts" :text="item.email ? item.email : 'Nav norādīts'" />
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ContactDetails",
  components: {
    ItemText
  },
  props: ['item'],
}
</script>

<style>

</style>