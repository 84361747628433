<template>
  <item-card :url="`/contacts/${item.searchable.id}`">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        {{item.title}}
      </h3>
    </template>

    <template v-slot:buttons>
      <span class="relative z-0 inline-flex rounded-md ml-3">
        <template v-if="item.searchable.branches.length > 0">
          <button type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0" :class="[showBranches ? 'bg-gray-100' : '', item.searchable.customers.length > 0 ? '' : 'rounded-r-md']" @click="showBranches = !showBranches">
            Filiāle
          </button>
        </template>

        <template v-if="item.searchable.customers.length > 0">
          <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0" :class="[showCustomers ? 'bg-gray-100' : '', item.searchable.branches.length > 0 ? '' : 'rounded-l-md']" @click="showCustomers = !showCustomers">
            Klienti
          </button>
        </template>
      </span>
    </template>

    <template v-slot:content>
      <!--  Display Branch main details -->
      <ContactDetails :item="item.searchable"/>
    </template>

    <template v-slot:additional>
      <!-- Show all customer contacts-->
      <template v-if="showCustomers">
        <ShowCustomers :customers="item.searchable.customers" :showCustomers="showCustomers"/>
      </template>

      <!--  Show all customer branches-->
      <template v-if="showBranches">
        <ShowBranches :branches="item.searchable.branches" :showBranches="showBranches"/>                
      </template>
    </template>

  </item-card>
</template>

<script>
import ContactDetails from "@/components/Customers/Search/ItemTypes/Contact/ContactDetails"
import ShowCustomers from "@/components/Customers/AdditionalFields/Customer/ShowCustomers";
import ShowBranches from "@/components/Customers/AdditionalFields/Branches/ShowBranches";
import ItemCard from "@/components/Components/ItemCard";

export default {
  name: "ContactType",
  components: {
    ContactDetails,
    ShowCustomers,
    ShowBranches,
    ItemCard,
  },
  props: ['item'],
  data(){
    return {
      showContacts: false,
      showBranches: false,
      showCustomers: false,
    }
  },
}
</script>

<style>

</style>