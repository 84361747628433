<template>
  <item-text>
    <template v-slot:content>
      <div class="h-full flex items-center">
        <div class="w-9 h-9 bg-gray-400 rounded-full flex justify-center align-center text-white">
          <span class="text-2xl">Fi</span>
        </div>
      </div>
    </template>
  </item-text>

  <item-text title="Nosaukums" :text="item.reg_nr ? item.reg_nr : 'Nav norādīts'"/>
  <item-text title="Reģ. nr / PK" :text="item.reg_nr ? item.reg_nr : 'Nav norādīts'" />
  <item-text title="Adrese" :text="item.address" />
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "BranchDetails",
  components: {
    ItemText
  },
  props: ['item'],
}
</script>

<style>

</style>