<template>
  <div class="w-full pt-3 border-t border-gray-200 dark:border-gray-700">
    <div class="w-full flex items-center">
      <h2 class="font-semibold text-lg pb-3">Klienti</h2>
    </div>

    <div class="w-full">
      <template v-for="(customer, index) in customers" :key="index">
        <CustomerDetails :customer="customer" />
      </template>
    </div>
  </div>
</template>

<script>
import CustomerDetails from "@/components/Customers/AdditionalFields/Customer/CustomerDetails"

export default {
  name: "ShowCustomers",
  components: {
    CustomerDetails,
  },
  props: ['customers', 'showContacts'],
  methods: {
    hideForm(){
      this.$emit('update:showCustomers', false)
    },
  }
}
</script>

<style>

</style>