<template>
  <router-link :to="`/customers/${customer.id}`" class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full px-2 rounded-lg">
    <div class="py-4">
      <div class="sm:flex flex-wrap">
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <UserIcon class="w-6 h-6" />
              </span>
              {{customer.name}}
            </p>
          </template>
        </ItemText>
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <LocationMarkerIcon class="w-6 h-6" />
              </span>
              {{customer.address ? customer.address : 'Nav norādīts'}}
            </p>
          </template>
        </ItemText>
        <ItemText>
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
              <span class="text-gray-400 mr-2">
                <IdentificationIcon class="w-6 h-6"/>
              </span>
              {{customer.reg_nr ? customer.reg_nr : 'Nav norādīts'}}
            </p>
          </template>
        </ItemText>

        <div class="ml-auto">
          <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
            <button class="text-gray-400 flex items-center">
              Skatīt klientu
              <ChevronRightIcon class="w-6 h-6"/>
            </button>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import {
  UserIcon,
  LocationMarkerIcon,
  IdentificationIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";

export default {
  name: "CustomerDetails",
  components: {
    ItemText,
    UserIcon,
    LocationMarkerIcon,
    IdentificationIcon,
    ChevronRightIcon,
  },
  props: ['customer'],
}
</script>

<style>

</style>