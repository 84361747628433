<template>
  <div class="w-full pt-3 border-t border-gray-200 dark:border-gray-700">
    <div class="w-full flex items-center">
      <h2 class="font-semibold text-lg pb-3">Filiāles</h2>
    </div>

    <div class="w-full">
      <template v-for="(branch, index) in branches" :key="index">
        <BranchDetails :branch="branch" :customerId="branch.customer_id" />
      </template>
    </div>
  </div>
</template>

<script>
import BranchDetails from "@/components/Customers/AdditionalFields/Branches/BranchDetails"

export default {
  name: "ShowContacts",
  components: {
    BranchDetails
  },
  props: ['branches', 'showBranches'],
  methods: {
    hideForm(){
      this.$emit('update:showBranches', false)
    },
  }
}
</script>

<style>

</style>