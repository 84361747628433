<template>
  <item-card :url="`/customers/${item.searchable.customer.id}/branches/${item.searchable.id}`">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        {{item.title}}
      </h3>
    </template>

    <template v-slot:buttons>
      <span class="relative z-0 inline-flex rounded-md ml-3">
        <template v-if="item.searchable.contacts.length > 0">
          <button type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0" :class="[showContacts ? 'bg-gray-100' : '', item.searchable.customer > 0 ? '' : 'rounded-r-md']" @click="showContacts = !showContacts">
            Kontakti
          </button>
        </template>

        <template v-if="item.searchable.customer">
          <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-0" :class="[showCustomers ? 'bg-gray-100' : '', item.searchable.contacts.length > 0 ? '' : 'rounded-l-md']" @click="showCustomers = !showCustomers">
            Klienti
          </button>
        </template>
      </span>
    </template>

    <template v-slot:content>
      <!--  Display Branch main details -->
      <BranchDetails :item="item.searchable"/> 
    </template>

    <template v-slot:additional>

      <!-- Show all customer contacts-->
      <template v-if="showContacts">
        <!-- ShowContacts -->
        <ShowContacts :contacts="item.searchable.contacts" :showContacts="showContacts"/>
      </template>

      <!-- Show all customer customers -->
      <template v-if="showCustomers">
        <ShowCustomer :customer="item.searchable.customer" :showCustomers="showCustomers"/> 
      </template>
    </template>

  </item-card>
</template>

<script>
import BranchDetails from "@/components/Customers/Search/ItemTypes/Branch/BranchDetails"
import ShowContacts from "@/components/Customers/AdditionalFields/Contacts/ShowContacts";
import ShowCustomer from "@/components/Customers/AdditionalFields/Customer/ShowCustomer";
import ItemCard from "@/components/Components/ItemCard";

export default {
  name: "BranchType",
  components: {
    BranchDetails,
    ShowContacts,
    ShowCustomer,
    ItemCard,
  },
  props: ['item'],
  data(){
    return {
      showContacts: false,
      showBranches: false,
      showCustomers: false,
    }
  },
}
</script>

<style>

</style>